export const MASK_FORMAT_PHONE = '(###) ###-####'
export const MASK_FORMAT_FAX = '(###) ###-####'
export const MASK_FORMAT_ZIP = ['#####']
export const MASK_FORMAT_TAX_ID = '##-#######'

export default {
  MASK_FORMAT_PHONE,
  MASK_FORMAT_FAX,
  MASK_FORMAT_ZIP,
  MASK_FORMAT_TAX_ID,
}
